/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background-color: #000000;
  color: #ffffff;
  font-size: 14px;
  position: relative;
}

#footer .footer-top {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 80px 0;
}

#footer .footer-top h3 {
  font-size: 36px;
  font-weight: 700;
  color: #ffffff;
  position: relative;
  font-family: "Poppins", sans-serif;
  padding-bottom: 0;
  margin-bottom: 0;
}

#footer .footer-top p {
  font-size: medium;
  font-style: normal;
  margin: 30px 0 0 0;
  padding: 0;
}

#footer .footer-top .footer-newsletter {
  text-align: left;
  font-size: 15px;
  margin-top: 30px;
}

#footer .footer-top .footer-newsletter form {
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
  text-align: left;
  border: 1px solid #ffffff;
}

#footer .footer-top .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-top .footer-newsletter form input[type=submit] {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #068e80;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-top .footer-newsletter form input[type=submit]:hover {
  background: #04655c;
}

#footer .footer-top .social-links {
  margin-top: 30px;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #ffffff;
  color: #04655c;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #04655c;
  color: #fff;
  text-decoration: none;
}

#footer .footer-bottom {
  border-top: 1px solid #ffffff;
  z-index: 2;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}

#footer .copyright {
  float: unset;
  text-align: center;
}

#footer .credits {
  float: right;
  font-size: 13px;
}
.pos-realtive{
  position: relative;
}
.play-button{
  border:none;
  background: unset;
  width: 120px;
  height: 120px;
  font-size: 85px;
  transform: translate(-50%, -50%);
  left:50%;
  top:50%;
  position: absolute;
  z-index: 9;
  color:#fff;
}
.counters i{
  font-size: 55px;
}
@media (max-width: 992px) {

  #footer .copyright,
  #footer .credits {
    float: none;
    -moz-text-align-last: center;
    text-align-last: center;
  }
}

.b-button{
    border: 1px solid #fff;
    color:#fff;
    max-width: 160px;
  }

  .b-button:hover, .b-button:focus{
    background-color: #fff;
    color:#04655c;
    border: 1px solid transparent;
  }

  .footer-newsletter .list-group .list-group-item+.list-group-item{
    background: transparent;
    border: none;
    padding: 7px 0;
    color:white;
    font-size: medium;
  }

  .footer-newsletter .list-group .list-group-item.active{
    background:none;
    color:#ffffff;
    border:none;
    font-size: 24px;
    padding:10px 0px;
  }
  .g-map{
    border:0; width: 100%; height: 290px;
  }
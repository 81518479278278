  /*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
  .about .content h3 {
    font-weight: 700;
    font-size: 32px;
    color: #5f687b;
  }
  
  .about .content ul, .team ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li, .team ul li {
    padding-bottom: 10px;
  }
  
  .about .content ul li{
    padding-bottom: 40px;
  }
  
  .about .content ul i, .team ul i {
    font-size: 18px;
    padding-right: 8px;
    color: #068e80;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  
  /*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
  .counts {
    padding-top: 0;
  }
  
  .counts .counters span {
    font-size: 48px;
    display: block;
    color: #068e80;
    font-weight: 700;
  }
  
  .counts .counters p {
    padding: 0;
    margin: 0 0 20px 0;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
  }
  